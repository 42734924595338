import { logger, setupLogging } from '@hpx-it/react-app';

const {
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_SERVICE_NAME,
  REACT_APP_COMMIT_REF,
} = process.env;

setupLogging({
  clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
  site: REACT_APP_DATADOG_SITE,
  service: REACT_APP_SERVICE_NAME,
});

logger.addContext('release', REACT_APP_COMMIT_REF);
