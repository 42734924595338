import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  NextPage,
  Pages,
  searchParams,
  useSendPageTransitionMessage,
} from 'utils';
import { Page, PageTransitionType } from 'types';
import { difference, union } from 'lodash';
import { logger, metricLogger } from '@hpx-it/react-app';

import { AudienceContext } from './AudienceContext';
import { ClientContext } from './ClientContext';
import { CustomerContext } from './CustomerContext';
import { RemoteAssistContext } from './RemoteAssistContext';

type PageContextProps = {
  page: Page;
  pageHistory: Page[];
  goToPreviousPage: () => void;
  goToNextPage: (page?: Page) => void;
  goToErrorPage: (error?: any) => void;
  showBackButtonOverride?: boolean;
  setShowBackButtonOverride: Dispatch<SetStateAction<boolean | undefined>>;
};

type PageProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: PageContextProps = {
  page: Page.WELCOME,
  pageHistory: [],
  goToPreviousPage: () => {},
  goToNextPage: () => {},
  goToErrorPage: () => {},
  setShowBackButtonOverride: () => {},
};

export const PageContext = createContext<PageContextProps>(DEFAULT_CONTEXT);

export const PageProvider = ({ children }: PageProviderProps) => {
  const {
    assignTo,
    transferTo,
    serviceCodeId,
    parentRemoteAssist,
    productType,
  } = useContext(RemoteAssistContext);
  const { validationResponse } = useContext(CustomerContext);
  const { showBackButton } = useContext(AudienceContext);
  const {
    bookingWidgetConfig,
    clientProducts,
    canBookWithHPA,
    canBookWithTechToTech,
    hasOneServiceCode,
  } = useContext(ClientContext);
  const sendPageTransitionMessage = useSendPageTransitionMessage();
  const [pageHistory, setPageHistory] = useState<Page[]>([]);
  const [skippedPages, setSkippedPages] = useState<string[]>(() => {
    return difference(
      union(searchParams.getAll('skipPage'), bookingWidgetConfig?.pages?.skip),
      searchParams.getAll('showPage'),
    );
  });
  const showPages = useMemo(() => {
    return searchParams.getAll('showPage');
  }, []);
  const [showBackButtonOverride, setShowBackButtonOverride] = useState<
    boolean | undefined
  >();
  const nextPageAccepts = useCallback(
    (nextPage: NextPage) =>
      !nextPage.accept ||
      nextPage.accept({
        assignTo: !!assignTo,
        transferTo: !!transferTo,
        parentRemoteAssistRelation: parentRemoteAssist?.relation,
        hasOneServiceCode,
        clientDemandProducts: clientProducts,
        productType,
        canBookWithHPA,
        canBookWithTechToTech,
        showBackButton,
      }),
    [
      assignTo,
      transferTo,
      parentRemoteAssist?.relation,
      hasOneServiceCode,
      clientProducts,
      productType,
      canBookWithHPA,
      canBookWithTechToTech,
      showBackButton,
    ],
  );
  const findNonSkippedPage = useCallback(
    (pageChoice: Page): Page => {
      const pageDetails = Pages[pageChoice];
      if (
        (skippedPages.includes(pageChoice) ||
          (pageDetails.showPage === false &&
            !showPages.includes(pageChoice))) &&
        (!pageDetails.canSkip ||
          pageDetails.canSkip({
            serviceCodeId,
            customerInputError: !validationResponse?.valid ?? true,
          }))
      ) {
        if (pageDetails.historyWhenSkipped !== false) {
          setPageHistory((pageHistory) => [...pageHistory, pageChoice]);
        }
        return findNonSkippedPage(
          pageDetails.nextPages.filter(nextPageAccepts)[0].page,
        );
      }
      setPageHistory((pageHistory) => [...pageHistory, pageChoice]);
      return pageChoice;
    },
    [
      validationResponse,
      serviceCodeId,
      showPages,
      skippedPages,
      nextPageAccepts,
    ],
  );

  const [page, setPage] = useState<Page>(() => {
    const initialPage = findNonSkippedPage(Page.WELCOME);
    sendPageTransitionMessage(
      undefined,
      initialPage,
      PageTransitionType.INITIAL,
    );
    return initialPage;
  });

  const goToPreviousPage = useCallback(() => {
    setPageHistory((pageHistory) => {
      if (pageHistory.length < 1) {
        return pageHistory;
      }
      const fromPage = pageHistory[pageHistory.length - 1];
      const toPage = pageHistory[pageHistory.length - 2];
      setPage(toPage);
      setSkippedPages((skippedPages) =>
        skippedPages.filter((page) => page !== toPage),
      );
      sendPageTransitionMessage(fromPage, toPage, PageTransitionType.PREVIOUS);
      return pageHistory.slice(0, pageHistory.length - 1);
    });
  }, [sendPageTransitionMessage]);

  const goToNextPage = useCallback(
    (pageChoice?: Page) => {
      setPage((fromPage) => {
        const options = Pages[fromPage].nextPages
          .filter(nextPageAccepts)
          .map((nextPage) => nextPage.page);
        const toPage = findNonSkippedPage(
          pageChoice && options.includes(pageChoice) ? pageChoice : options[0],
        );
        sendPageTransitionMessage(fromPage, toPage, PageTransitionType.NEXT);
        return toPage;
      });
    },
    [findNonSkippedPage, sendPageTransitionMessage, nextPageAccepts],
  );

  useEffect(() => {
    setShowBackButtonOverride(undefined);
  }, [page]);

  return (
    <PageContext.Provider
      value={{
        page,
        pageHistory,
        goToPreviousPage,
        goToNextPage,
        goToErrorPage: (error?: any) => {
          if (error) {
            logger.error('Error page rendered', { message: error });
            metricLogger.log({ name: 'ErrorPageRendered', value: 1 });
          }
          setPageHistory((pageHistory) => [...pageHistory, Page.ERROR]);
          setPage(Page.ERROR);
          sendPageTransitionMessage(
            pageHistory.length > 0
              ? pageHistory[pageHistory.length - 1]
              : undefined,
            Page.ERROR,
            PageTransitionType.ERROR,
          );
        },
        showBackButtonOverride,
        setShowBackButtonOverride,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
